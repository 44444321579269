.footer-component{
    margin-left: 180px;
    margin-right: 200px;
}

@media screen and (max-width:600px){
    .footer__links{
        display: flex;
        margin:10px
    }
    .footer__item{
       padding:10px;
    }
    .footer-component{
        margin-left: 10px;
    }

}
@media screen and (max-width:900px){
    .footer__links{
        display: flex;
        margin:10px
    }
    .footer__item{
       padding:10px;
    }
    .footer-component{
        margin-left: 10px;
    }

}
@media screen and (max-width:1100px){
    .footer__links{
        display: flex;
        margin:10px
    }
    .footer__item{
       padding:10px;
    }
    .footer-component{
        margin-left: 10px;
    }

}