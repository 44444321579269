.sale__card{
    background-color:#16151A;
    padding: 1.5rem;
    border: none;
    border-radius: 8px;
    background-color: var(--card-bg-color);
    -webkit-box-shadow: 0 3px 20px 0px var(--primary-l-color);
            box-shadow: 0 3px 20px 0px var(--primary-l-color);
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
            transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    width:345px;
    margin-right:25px;
}
.saleCard__container{
    margin-top:35px;
    margin-left:20px;

}
.saleCard__content{
    margin-top:26px;
    margin-right:40px;
    padding-right:20px
}
.saleCard__owner{
  font-size:12px;
  color:white;
  font-weight:bold;
}