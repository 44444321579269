.editProfile{
    display: flex;
       height: max-content;
       /* margin-left: ; */
     
    }
    .editProfile__container1{
        padding: 150px;
    
    }
    .form__text{
       padding-bottom: 20px;
       padding-top: 40px;
       font-weight: 400;
       /* color: #8625c2; */
       color: black;



    }
    .editProfile__text{
        font-size: 45px;
        margin-bottom: 20px;
        font-weight: 500;
        color: white;
        /* color: #8834bd; */


    }
    .form__input{
        height: 40px;
        width: 500px;
        background-color: white;
              color: #57048A;
        
        border: 1px solid white;

        border-radius: 18px;
    }
    .form__verification{
        padding-top: 50px;
    }
    .form__verification3{
        display: flex;
        flex-direction: column;
    }
.form__verification2{
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
}
.form__verification__button{
height: 40px;
margin: 15px;
border: none;
background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
cursor: pointer;
padding-left: 12px;
padding-right: 12px;
color: white;

border-radius: 10px;
}
.editProfile__button{
    margin-top: 20px;
    width: 500px;
    height: 50px;
    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));

    border: 1px solid white;
    color: white;
    border: none;
    border-radius: 20px;
}
.editProfile__container2{
    margin-top: 150px;
}
.profile__button{
    width: 250px;
    height: 50px;
    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
    border-radius: 20px;
    margin-top: 30px;
    border: none;
    color: white;



}
@media screen and(min-width:600px){
    .editProfile{
        margin-left: 50px;
    }
    .editProfile__container1{
        padding: 50px;
    }
}
