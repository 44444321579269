.card1{
    display: flex;
    /* background-color: var(--card-bg-color); */
    height: 90px;
    width: 540px;
    padding-top: 12px;
    border-radius: 7px;
    padding-left: 20px;
    margin-left: 8px;
    border:1px solid var(--secondary-color) 
}
.card1-text{
    margin-left: 10px;
    margin-bottom: 30px;
    font-Size: 12px;
   cursor: "pointer";
   color: "black";
   margin-right:8px;
   font-weight: bold;
                    
}
.card1-title{
    margin-left: 10px;
    padding-top: 10px;
}

.imageTag{
    margin-right: 30px;
}
.card__content{
    display: flex;
    
}
.card__creator{
    margin-top: 18px;
    margin-left: 60px;
    position:static;
}
.price__text{
    margin-bottom: 50px;
}
.card2{
    display: flex;
    /* background-color: var(--card-bg-color); */
    height: 90px;
    width: 540px;
    padding-top: 12px;
    border-radius: 7px;
    padding-left: 20px;
    border:1px solid var(--secondary-color) 


}
.card2-content{
    display:flex;
    flex-direction:column;
    margin-top:6px;
}
@media (max-width: 500px){
    .card1{
        width:330px;
    }
    .card1-text{
        font-size:xx-small;
    }
    .card2{
        width:320px;
    }
    .history-text{
        font-size: xx-small;
    }
    .bid__history{
        font-size: smaller;
        width:200px
    }

}