.editProfile{
    display: flex;
       height: max-content;
       /* margin-left: 300px; */
     
    }
    .editProfile__container1{
        padding: 200px;
        padding-left: 500px;
    
    }
    .form__text{
       padding-bottom: 20px;
       padding-top: 40px;
       font-weight: 400;
       /* color: #8625c2; */
       color: white;



    }
    .editProfile__text{
        font-size: 45px;
        margin-bottom: 20px;
        font-weight: 500;
        color: white;
        /* color: #8834bd; */


    }
    .form__input{
        height: 40px;
        width: 500px;
        background-color: white;
              color: #57048A;
        
        border: 1px solid white;

        border-radius: 18px;
    }
    .form__verification{
        padding-top: 50px;
    }
    .form__verification3{
        display: flex;
        flex-direction: column;
    }
.form__verification2{
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
}
.form__verification__button{
height: 40px;
margin: 15px;
border: none;
background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
cursor: pointer;
padding-left: 12px;
padding-right: 12px;
color: white;

border-radius: 10px;
}
.editProfile__button{
    margin-top: 20px;
    width: 500px;
    height: 50px;
    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));

    border: 1px solid white;
    color: white;
    border: none;
    border-radius: 20px;
}
.editProfile__container2{
    margin-top: 150px;
}
.profile__button{
    width: 250px;
    height: 50px;
    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
    border-radius: 20px;
    margin-top: 30px;
    border: none;
    color: white;



}
.img__input{
    margin-top: 10px;
    cursor: pointer;
}
.form__verification3{
    margin-top: 15px;
}

@media screen and (max-width:600px){
    .editProfile__container1{
        padding: 2px;
        padding-top: 100px;
        width:100px;
    }
    .editProfile{
        margin-left: 50px;
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: medium;
    }
    .form__input{
        width: 100px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 150px;
        height: 150px;
    }
    
}
@media screen and (max-width:1000px){
    .editProfile__container1{
        padding: 2px;
        padding-top: 100px;
        width:250px;
    }
    .editProfile{
        margin-left: 150px;
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: medium;
    }
    .form__input{
        width: 150px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 150px;
        height: 150px;
    }

}
@media screen and (max-width:1300px){
    .editProfile__container1{
        padding: 2px;
        padding-top: 100px;
        width:350px;
    }
    .editProfile{
        margin-left: 150px;
        margin-bottom:100px
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: medium;
    }
    .form__input{
        width: 150px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 150px;
        height: 150px;
    }

}
@media screen and (max-width:400px){
    .editProfile__container1{
        padding: 2px;
        padding-top: 100px;
        width:100px;
    }
    .editProfile{
        margin-left: 30px;
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: medium;
    }
    .form__input{
        width: 80px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 50px;
        height: 50px;
    }

}
@media screen and (max-width:500px){
    .editProfile__container1{
        padding: 2px;
        padding-top: 100px;
        width:120px;
    }
    .editProfile{
        margin-left: 30px;
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: medium;
    }
    .form__input{
        width: 90px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 50px;
        height: 50px;
    }
}
/* @media screen and (max-width:1500px){
    .editProfile__container1{
        padding: 100px;
        padding-top: 150px;
        width:500px;
        margin-right:100px
    }
    .editProfile{
        margin-left: 100px;
    }
    .editProfile__text{
        font-size: large;
    }
    .form__text{
        font-size: large;
    }
    .form__input{
        width: 300px;
    }
    .form__verification{
        font-size: medium;
    }
    .form__verification__text{
        font-size: medium;
    }
    .form__verification__text2{
        font-size: smaller;
        margin-right: 5px;
        width: 100px;
    }
    .editProfile__button{
        width: 250px;
    }
    .editProfile__container2{
        padding: 1px;
        
    }
    .profile__button{
        width: 180px;
    }
    .avatar__img{
        width: 50px;
        height: 50px;
    }
} */