.hero__title{
    display:flex;
    width:1000px;
    margin-left:185px;
}
.hero__img{
    width:500px;
    height:510px
}
.hero__imgContainer{
    margin-right:85px

}
.hero__content{
    width:500px
}
@media screen and (max-width:600px){
    .hero__title{
        width:500px;
        margin-left:10px
    }
    

}
@media screen and (max-width:400px){
    .hero__title{
        width:380px;
        margin-left:10px
    }
    .hero__content{
        width:300px
    }
    
}
@media screen and (max-width:700px){
    .hero__title{
        width:500px;
        margin-left:10px
    }
    .hero__img{
        width:400px;
        height:400px
    }
    .hero__imgContainer{
        margin-right:10px
    
    }

}
@media screen and (max-width:500px){
    .hero__title{
        width:300px;
        margin-left:10px
    }
    .hero__img{
      display:none;
    }
}
@media screen and (max-width:800px){
    .hero__title{
        width:1000px;
        margin-left:10px
    }
    .hero__img{
        width:400px;
        height:400px
    }
    .hero__imgContainer{
        margin-right:10px
    
    }

}
@media screen and (min-width: 300px) and (max-width: 1000px){
    .hero__title{
        width:300px;
        padding:0px;
        margin-left:20px;
        margin-right:70px;
        padding-right:30px

    }
    .hero__content{
        width:350px
    }

}
@media screen and(min-width: 992px){
    .col-lg-8{
      max-width:fit-content;
    }
}

@media  screen and(max-width:1200px){
    .hero__title{
        width:500px;
        margin-left:80px;
        margin-right:50px
    }
    .hero__row{

    }
}